import React from "react";

import Layout from "../Components/Layout";
import Staticstics from "../Components/Statistics";

const Index = () => {
  return (
    <Layout isPrivate showHeader>
      <Staticstics />
    </Layout>
  );
};

export default Index;
