import React, { useEffect } from "react";

import { useState } from "react";
import { useState as useHookState } from "@hookstate/core";
import { AxiosError } from "axios";

import StatisticsCard from "../StatisticsCard";
import Modal from "../Modal";
import StatisticsFilter from "../Modals/StatisticsFilter";
import Loader from "../Loader";

import Server from "../../utils/Server";

import AppState from "../../States/AppState";

import { Statistics } from "../../Interfaces/Statictics";

import CalenderIcon from "../../images/booking_calendar@2x.png";

import * as StatisticsStyle from "./Statistics.module.scss";
import { getFormattedDate } from "../../utils/FormatTime";
import Logger from "../../utils/Logger";

const Index = () => {
  const user = useHookState(AppState).user.get();
  const [statistics, setStatistics] = useState<Statistics[]>();
  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilter] =
    useState<{ startDate?: string; endDate?: string }>();
  const [isFilter, setIsFilter] = useState(false);

  useEffect(() => {
    const shortUserId = user?.id?.split("/")[2];
    const start = filter?.startDate
      ? `startDate=${getFormattedDate(filter.startDate)}`
      : "";
    const end = filter?.endDate
      ? `startDate=${getFormattedDate(filter.endDate)}`
      : "";

    setIsLoading(true);
    Server.get(
      `/parkingarea/owner/${shortUserId}/earning?${start}${
        start !== "" ? `&${end}` : end
      }`
    )
      .then((response: any) => {
        setStatistics(response);
      })
      .catch((error: AxiosError) => {
        if (error.response?.data.message !== "partner has no active-parkings") {
          Logger.showError(error);
        }
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <>
      <div className={StatisticsStyle.container}>
        <div className={StatisticsStyle.header}>
          <h5 className="color-primary textMd">Statistics</h5>
          {statistics?.length && (
            <button onClick={() => setIsFilter(true)}>
              <img src={CalenderIcon} alt="filter" width={28} height={28} />
            </button>
          )}
        </div>
        {statistics?.map((statistic) => (
          <StatisticsCard key={statistic.parkingArea} statistics={statistic} />
        ))}

        {!statistics?.length && isLoading === false && (
          <h6 className="color-primary textMd">No Statistics</h6>
        )}
      </div>
      {isFilter && (
        <Modal
          isOpen={isFilter}
          title="Select Date Range"
          onClose={() => setIsFilter(false)}
          showHeader
        >
          <StatisticsFilter
            filter={filter}
            onSave={(date) => {
              setFilter(date);
            }}
          />
        </Modal>
      )}
      <Loader isLoading={isLoading} />
    </>
  );
};

export default Index;
