import React, { useRef, useState } from "react";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import * as StatisticsFilterStyle from "./StatisticsFilter.module.scss";

interface FilterData {
  startDate?: string;
  endDate?: string;
}

interface Props {
  filter?: FilterData;
  onSave: (data: FilterData) => void;
}

const Index = ({ onSave, filter }: Props) => {
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const onHandalSubmit = () => {
    onSave({
      startDate: endDate?.toISOString(),
      endDate: startDate?.toISOString(),
    });
  };

  return (
    <div className={StatisticsFilterStyle.container}>
      <button className={StatisticsFilterStyle.clear__btn}>Clear All</button>
      <div className={StatisticsFilterStyle.dates}>
        <DatePicker
          selected={startDate}
          isClearable
          selectsStart
          dateFormat="dd-MM-yyyy"
          onChange={(date) => {
            if (date) setStartDate(date);
          }}
          placeholderText="From Date"
        />
        <DatePicker
          selected={endDate}
          isClearable
          selectsStart
          dateFormat="dd-MM-yyyy"
          onChange={(date) => {
            if (date) setEndDate(date);
          }}
          placeholderText="To Date"
        />
      </div>

      <button
        onClick={onHandalSubmit}
        className={StatisticsFilterStyle.search__btn}
      >
        Search
      </button>
    </div>
  );
};

export default Index;
