import React from "react";
import { Statistics } from "../../Interfaces/Statictics";

import StatisticsIcon from "../../images/statistic@2x.png";

import * as StatisticsCard from "./StatisticsCard.module.scss";

interface Props {
  statistics: Statistics;
}

const Index = ({ statistics }: Props) => {
  return (
    <div className={StatisticsCard.card}>
      <div className={`${StatisticsCard.card__header} grediant1`}>
        <h6>{statistics.name}</h6>
      </div>
      <div className={StatisticsCard.card__content}>
        <div className={StatisticsCard.card__content__item}>
          <img src={StatisticsIcon} alt="statictics" width={64} height={64} />
          <div className={StatisticsCard.card__content__detail}>
            <span>Total Annul Income</span>
            <span>&#8377;&nbsp;{statistics.yearlyEarning}</span>
          </div>
        </div>
        <div className={StatisticsCard.card__content__item}>
          <img src={StatisticsIcon} alt="statictics" width={64} height={64} />
          <div className={StatisticsCard.card__content__detail}>
            <span>Total Income For The Month</span>
            <span>&#8377;&nbsp;{statistics.monthlyEarning}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
